import EntityQueryRepositoryInterface from '@/plugins/Test/domain/repository/EntityQueryRepositoryInterface'

export default class EntitiesHandler {
    private repo: EntityQueryRepositoryInterface;

    constructor (repo: EntityQueryRepositoryInterface) {
      this.repo = repo
    }

    execute (payload: string[][]): Promise<any> {
      return this.repo.getEntities(payload)
    }
}
